<template>
  <div>
    <a-modal
      v-model="visibleModalManageGroup"
      class="mod"
      :title="status.isEdit ? 'Form Edit Group' : 'Form Create Group'"
      @cancel="toggleModalManageGroup"
      centered
    >
      <form-group :dataGroup="newGroup" @handle-change="handleChange" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageGroup">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageGroup"
          @click="handleOkModalManageGroup"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageGroup('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Study Group</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="groupColumns"
        :data-source="groupDataTable"
        :pagination="groupPagination"
        :loading="groupLoadingTable"
        bordered
      >
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManageGroup('edit', record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteGroup(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const formGroup = () => import('@/components/app/Admin/Master/Form/Group')

const groupColumns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'Group',
  components: {
    formGroup,
  },
  data() {
    return {
      groupColumns,
      visibleModalManageGroup: false,
      loadingActionModalManageGroup: false,
      loadingDeleteGroup: false,
      groupLoadingTable: false,
      groupDataTable: [],
      groupPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newGroup: {
        nama: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      console.log('parent', payload)
      this.newGroup[column] = value
    },
    toggleModalManageGroup(status, data) {
      this.visibleModalManageGroup = !this.visibleModalManageGroup
      if (!this.visibleModalManageGroup) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newGroup = {
            nama: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newGroup = {
            nama: data.nama,
          }
        }
      }
    },
    handleOkModalManageGroup() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this study group?' : 'Are you sure want to create new study group'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageGroup = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataGroup: this.newGroup,
            } : {
              dataGroup: this.newGroup,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_GROUP`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageGroup = false
                this.toggleModalManageGroup()
                this.fetchDataGroup()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Study Group has been updated' : 'Study Group has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Study Group has not been updated' : 'Study Group has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteGroup(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this study group?</div>
        ),
        onOk: () => {
          this.groupLoadingTable = true
          this.loadingDeleteGroup = true
          this.$store.dispatch('master/DELETE_GROUP', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataGroup()
              this.loadingDeleteGroup = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Study Group has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Study Group has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteGroup = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataGroup(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.groupLoadingTable = true
      this.$store.dispatch('master/FETCH_GROUP', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          console.log('fetchDataGroup res => ', res)
          this.groupLoadingTable = false
          const pagination = { ...this.groupPagination }
          pagination.total = res.total
          this.groupPagination = pagination
          this.groupDataTable = res.data.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataGroup()
  },
  computed: {
    isValidateForm() {
      if (this.newGroup.nama) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
